import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";

import type { NotificationDetails } from "../../providers/NotificationsProvider";

import AnchorButton from "./AnchorButton";

export function Notification({
  type,
  title,
  message,
  action,
  disappearing,
  onClose,
}: NotificationDetails & { onClose: () => void }): JSX.Element {
  return (
    <Transition
      aria-live="assertive"
      className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5"
      show={!disappearing}
      leave="transition-all transition-max-h duration-300"
      leaveFrom="opacity-100 max-h-40"
      leaveTo="opacity-0 max-h-0"
    >
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            {type === "error" && <ExclamationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />}
            {type === "success" && <CheckCircleIcon className="w-6 h-6 text-green-400" aria-hidden="true" />}
          </div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            {title && <p className="text-sm font-semibold text-gray-900 mb-0.5">{title}</p>}
            {message && (
              <p className="text-sm text-gray-500">
                {message}
                {action && (
                  <AnchorButton onClick={action.onClick} color="primary">
                    {action.label}
                  </AnchorButton>
                )}
              </p>
            )}
          </div>
          <div className="flex flex-shrink-0 ml-4">
            <button
              type="button"
              className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none"
              onClick={onClose}
            >
              <span className="sr-only">Close</span>
              <XMarkIcon className="w-5 h-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </Transition>
  );
}

export function NotificationsContainer({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <div className="fixed inset-0 flex items-start flex-col space-y-4 px-4 py-6 pointer-events-none sm:items-end sm:p-6 z-100">
      {children}
    </div>
  );
}
