import { useEffect } from "react";

import { productName } from "./formatters/billing";
import { useModal } from "./providers/ModalProvider";
import useRedirect from "./use/redirect";
import { standardRailsUnauthorizedResponses } from "./api-client";

import { EndpointAuth, SubscriptionIssues } from "~services/app/endpointGuards";

type RailsErrorReasonsExceptMfa =
  | Exclude<(typeof standardRailsUnauthorizedResponses)[number], "admin:mfa_required">
  | "unexpected";

type ApiErrorReason =
  | {
      auth: EndpointAuth;
    }
  | {
      subscriptionIssues: SubscriptionIssues;
    }
  | "unexpected"
  | "request_too_large";

const requiredRoleDescriptions: Record<EndpointAuth, string> = {
  "public": "NA",
  "user_any_team": "You must be logged in to perform this action.",
  "admin": "You must be an admin to perform this action.",
  "guest": "You are already logged in. Please log out to perform this action.",
  "member": "You must be a member of this team to perform this action.",
  "owner": "You must be the owner of this team to perform this action.",
  "member:canAccessSettings": "Please ask your admin for settings access to continue.",
  "member:canManageBilling": "Please ask your admin for billing access to continue.",
  "member:canUse": "Please ask your admin for access to continue.",
  "member:canManageContent": "Please ask your admin for content management access to continue.",
  "member:canViewReporting": "Please ask your admin for reporting access to continue.",
  "member:canManageUsers": "Please ask your admin for user management access to continue.",
  "admin:accounts_view": "Please ask the tech team for accounts view access to continue.",
  "admin:accounts_write": "Please ask the tech team for accounts write access to continue.",
  "admin:sales_lead_target": "Please ask the tech team for sales lead target access to continue.",
  "admin:customer_success": "Only Customer Success Managers can perform this action.",
  "admin:impersonate": "Please ask the tech team for impersonation access to continue.",
  "admin:sales_exec": "Only Sales Executives can perform this action.",
  "admin:sales_manager": "Only Sales Managers can perform this action.",
  "admin:sales_view": "Please ask the tech team for sales view access to continue.",
  "admin:sales_write": "Please ask the tech team for sales write access to continue.",
  "admin:sidekiq": "Please ask the tech team for Sidekiq access to continue.",
  "admin:superuser": "Only super admins can perform this action.",
  "admin:evidence": "Please ask the tech team for evidence access to continue.",
};

const subscriptionStatusDescriptions: Record<
  Exclude<SubscriptionIssues["requiredSubscriptionStatus"], undefined>,
  string
> = {
  "active":
    "Your subscription is currently inactive. Please reach out to your account manager to reactivate your subscription.",
  "active:subscribed": "You can only perform this action if you have an active subscription.",
  "active:trial": "You can only perform this action if you are on trial.",
  "inactive": "You already have an active subscription.",
};

export default function ApiErrorsListener({ children }: { children: JSX.Element }) {
  const { addModal } = useModal();
  const redirect = useRedirect();
  useEffect(() => {
    const listener = (event: CustomEvent<{ reason: RailsErrorReasonsExceptMfa }>) => {
      switch (event.detail.reason) {
        case "permission:denied":
          addModal({
            title: "Permission denied",
            content: "You do not have permission to perform this action.",
          });
          break;
        case "auth:not_logged_in":
          addModal({
            title: "Not logged in",
            content: "You must be logged in to perform this action.",
            onClick: () => {
              location.reload();
            },
          });
          break;
        case "auth:logged_in":
          addModal({
            title: "Already logged in",
            content: "You are already logged in.",
            onClick: () => {
              location.reload();
            },
          });

          break;
        case "auth:mfa_setup_required":
          addModal({
            title: "MFA setup required",
            content: "Please set up your MFA before continuing.",
            onClick: () => {
              redirect(["up.auth.mfa.enable"]);
            },
          });
          break;

        case "admin:not_admin":
          addModal({
            title: "Not an admin",
            content: "You must be an admin to perform this action.",
          });
          break;
        case "admin:impersonating":
          addModal({
            title: "Impersonating",
            content: "You cannot perform this action while impersonating another  user.",
          });
          break;
        case "admin:wrong_role":
          addModal({
            title: "Please contact your manager",
            content: "Your manager's permission is required for you to perform this action.",
          });
          break;
        case "billing:subscription_needed":
          addModal({
            title: "Please subscribe",
            content: "You must have a subscription to perform this action.",
            onClick: () => {
              redirect(["up.shortCuts.purchase"]);
            },
          });
          break;
        case "billing:missing_feature":
          addModal({
            title: "Upgrade required",
            content:
              "Your subscription does not currently have access to this feature. Please reach out to your account manager to upgrade your account.",
          });
          break;
        case "billing:change_not_allowed":
          addModal({
            title: "Please contact us",
            content: "Please reach out to your account manager to make changes to your subscription.",
          });
          break;
        case "team_status:baa":
          addModal({
            title: "BAA protected",
            content: "Because this team has BAA protections activated, some features are not available.",
          });
          break;
        case "team_status:banned":
          addModal({
            title: "Please contact us",
            content: "We have tried reaching out to you about your account. Please contact us to resolve this issue.",
            onClick: () => {
              redirect(["up.root"]);
            },
          });
          break;
        case "team_status:on_premise":
          addModal({
            title: "On premise",
            content: "Some features are not available because your account is configured to be hosted on-premise.",
          });
          break;
        case "team_status:not_on_premise":
          addModal({
            title: "Not on premise",
            content: "Please contact us if you wish to host your account on-premise.",
          });
          break;
        case "unexpected":
          addModal({
            title: "Unexpected error",
            content: "An unexpected error has occurred. Please try again later.",
          });
          break;
      }
    };
    document.addEventListener("railserror", listener as EventListener);

    return () => {
      document.removeEventListener("railserror", listener as EventListener);
    };
  }, [addModal, redirect]);

  useEffect(() => {
    const listener = (event: CustomEvent<{ reason: ApiErrorReason }>) => {
      if (event.detail.reason === "request_too_large") {
        addModal({
          title: "Request too large",
          content:
            "Unfortunately your browser made a request too large, and our server could not process it. Please try again.",
          onClick: () => {
            location.reload();
          },
        });
      } else if (event.detail.reason === "unexpected") {
        addModal({
          title: "Unexpected error",
          content:
            "Unfortunately we have encountered an unexpected error. Our team has been notified. Please try again.",
          onClick: () => {
            location.reload();
          },
        });
      } else if ("auth" in event.detail.reason) {
        const requiredRole = event.detail.reason.auth;
        addModal({
          title: "Not authorized",
          content: requiredRoleDescriptions[requiredRole],
          onClick: () => {
            location.reload();
          },
        });
      } else if ("subscriptionIssues" in event.detail.reason) {
        const { missingFeatures, productNeeded, requiredSubscriptionStatus } = event.detail.reason.subscriptionIssues;

        if (missingFeatures) {
          addModal({
            title: "Upgrade required",
            content:
              "Your subscription does not currently have access to this feature. Please reach out to your account manager to upgrade your account.",
          });
        } else if (productNeeded) {
          addModal({
            title: "Please subscribe",
            content: `You must have a ${productName(productNeeded[0]!)} subscription to perform this action.`,
          });
        } else if (requiredSubscriptionStatus) {
          addModal({
            title: "Invalid subscription status",
            content: subscriptionStatusDescriptions[requiredSubscriptionStatus],
            onClick: () => {
              if (requiredSubscriptionStatus.startsWith("active")) redirect(["up.shortCuts.purchase"]);
              else redirect(["up.root"]);
            },
          });
        }
      } else {
        addModal({
          title: "Unexpected error",
          content: "An unexpected error has occurred. Please try again later.",
        });
      }
    };
    document.addEventListener("apierror", listener as EventListener);

    return () => {
      document.removeEventListener("apierror", listener as EventListener);
    };
  }, [addModal, redirect]);

  return children;
}

export function makeRailsErrorEvent(reason: RailsErrorReasonsExceptMfa) {
  return new CustomEvent<{ reason: RailsErrorReasonsExceptMfa }>("railserror", {
    detail: {
      reason,
    },
  });
}

export function makeApiErrorEvent(reason: ApiErrorReason) {
  return new CustomEvent<{ reason: ApiErrorReason }>("apierror", {
    detail: {
      reason,
    },
  });
}
