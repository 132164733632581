import { useState } from 'react';
import { useRafCallback } from '../useRafCallback';
import { useUnmountEffect } from '../useUnmountEffect';
/**
 * Like `React.useState`, but state is only updated within animation frame.
 */
export function useRafState(initialState) {
    const [state, innerSetState] = useState(initialState);
    const [setState, cancelRaf] = useRafCallback(innerSetState);
    useUnmountEffect(cancelRaf);
    return [state, setState];
}
