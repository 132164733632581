import { createContext, useCallback, useContext, useEffect, useState } from "react";

import { ApiRequests, ApiResponses, Successful } from "../data/api-endpoints";

const debug = require("debug")("upscope:api-cache");

const ApiCacheContext = createContext({
  setCache<T extends keyof ApiRequests>(key: T, data: ApiRequests[T] | undefined, value: any) {},
  forgetCache(key: keyof ApiRequests) {},
  invalidateCache(cutoff: Date, ids: string[]) {},
  getCache<T extends keyof ApiRequests>(
    key: T,
    data: ApiRequests[T] | undefined,
  ): [boolean, Successful<ApiResponses[T]>] | [false, undefined] {
    throw new Error("Cache not initialized");
    return [false, undefined];
  },
  newVersionAvailable: false,
});

export function useApiCache() {
  return useContext(ApiCacheContext);
}

export function makeNewVersionAvailableEvent() {
  return new CustomEvent("new_version_available");
}

const EIGHT_HOURS = 1000 * 60 * 60 * 8;

export default function ApiCacheProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [apiCache, setApiCache] = useState(new Map<string, Map<string, [Date, any]>>());
  const [newVersionAvailable, setNewVersionAvailable] = useState<boolean>(false);
  const [lastActivityAt, setLastActivityAt] = useState<number>(Date.now());
  const [previousActivityAt, setPreviousActivityAt] = useState<number>(Date.now());

  useEffect(() => {
    const listener = (event: CustomEvent) => {
      setNewVersionAvailable(true);
    };
    document.addEventListener("new_version_available", listener as EventListener);

    return () => {
      document.removeEventListener("new_version_available", listener as EventListener);
    };
  }, []);

  useEffect(() => {
    const listener = () => {
      if (Date.now() - lastActivityAt < 60_000) {
        return;
      }
      setPreviousActivityAt(lastActivityAt);
      setLastActivityAt(Date.now());
    };

    document.addEventListener("mousemove", listener);

    return () => {
      document.removeEventListener("mousemove", listener);
    };
  }, [lastActivityAt]);

  useEffect(() => {
    if (newVersionAvailable && Date.now() - previousActivityAt > EIGHT_HOURS) {
      location.reload();
    }
  }, [previousActivityAt, newVersionAvailable]);

  const forgetCache = useCallback((key: keyof ApiRequests) => {
    setApiCache((cache) => {
      cache.delete(key);
      return new Map(cache);
    });
  }, []);

  const setCache = useCallback(
    <T extends keyof ApiRequests>(key: T, data: ApiRequests[T] | undefined, value: Successful<ApiResponses[T]>) => {
      setApiCache((cache) => {
        const keyCache = cache.get(key) ?? new Map<string, [Date, any]>();
        keyCache.set(JSON.stringify(data ?? "-"), [new Date(), value]);
        cache.set(key, keyCache);

        if (cache.size > 100) {
          cache.delete(cache.keys().next().value);
        }
        return new Map(cache);
      });
    },
    [],
  );

  // Returns [isFresh: boolean, data: ApiRequests[T] | undefined]
  const getCache = useCallback(
    <T extends keyof ApiRequests>(
      key: T,
      data: ApiRequests[T] | undefined,
    ): [boolean, Successful<ApiResponses[T]>] | [false, undefined] => {
      const cached = apiCache.get(key)?.get(JSON.stringify(data ?? "-"));
      if (cached) {
        const [date, value] = cached;
        return [new Date().getTime() - date.getTime() < 1000 * 60, value];
      }
      return [false, undefined];
    },
    [apiCache],
  );

  const invalidateCache = useCallback((cutoff: Date, ids: string[]) => {
    debug("Invalidating cache by %O", ids);
    setApiCache((apiCache) => {
      for (const endpoint of apiCache.keys()) {
        for (const [dataKey, [date, cacheValue]] of apiCache.get(endpoint)!.entries()) {
          if (date > cutoff) {
            continue;
          }
          if (ids.every((id) => deepExists(cacheValue, id))) {
            debug("Invalidating cache %s / %s", endpoint, dataKey);
            const keyCache = apiCache.get(endpoint)!;
            keyCache.set(dataKey, [new Date(0), cacheValue]);
          }
        }
      }
      return new Map(apiCache);
    });
  }, []);

  return (
    <ApiCacheContext.Provider value={{ setCache, getCache, forgetCache, newVersionAvailable, invalidateCache }}>
      {children}
    </ApiCacheContext.Provider>
  );
}

const deepExists = (obj: Record<string, any>, query: string): boolean =>
  Object.values(obj).some((v) => (typeof v === "object" && v !== null ? deepExists(v, query) : v === query));
