import { z } from "zod";

export const AdminRole = z.enum([
  "sidekiq",
  "accounts_view",
  "accounts_write",
  "sales_lead_target",
  "impersonate",
  "superuser",
  "sales_view",
  "sales_write",
  "sales_manager",
  "sales_exec",
  "customer_success",
  "evidence",
]);
export type AdminRole = z.infer<typeof AdminRole>;

// Returned by api in camel case
export const AdminRoleKeys = z.enum([
  "evidence",
  "sidekiq",
  "accountsView",
  "accountsWrite",
  "impersonate",
  "superuser",
  "salesView",
  "salesWrite",
  "salesManager",
  "salesExec",
  "customerSuccess",
  "salesLeadTarget",
]);
export type AdminRoleKeys = z.infer<typeof AdminRoleKeys>;
