import { z } from "zod";

import { zNoEmptyObject } from "../../utils";
import { Charge } from "../models/Charge";
import {
  FreshchatIntegration,
  FrontIntegration,
  TwilioFlexIntegration,
  ZapierIntegration,
  ZendeskIntegration,
} from "../models/integrations";
import { Invoice } from "../models/Invoice";
import { CountryCode } from "../models/partials/CountryCode";
import { Feature } from "../models/partials/Feature";
import { PaymentSourceData } from "../models/partials/PaymentSourceData";
import { Product } from "../models/partials/Product";
import { Url } from "../models/partials/Url";
import { CobrowsingTeamUsage } from "../models/reporting/TeamUsage";
import { TeamBillingInfoData } from "../models/Team";
import { Invite, TeamMembershipTeamManagementData } from "../models/TeamMembership";
import { User } from "../models/User";
import { VisitorModel } from "../models/Visitor";

import { ErrorResponse, Pagy, SuccessResponse } from "./standard-responses";

import { TeamSettings } from "~data/TeamSettings";
import { TeamOnPremiseSettings } from "~data/TeamSettings";

export const settingsEndpoints = {
  "up.settings.personal.setup": () => ["up", "get", "/settings/personal/setup"],
  "up.settings.personal.update": () => ["up", "put", "/settings/personal"],
  "up.settings.personal.password.update": () => ["up", "put", "/settings/personal/password"],
  "up.settings.personal.password.disable": () => ["up", "delete", "/settings/personal/password"],
  "up.settings.personal.mfa.disable": () => ["up", "delete", "/settings/personal/mfa"],
  "up.settings.personal.remoteLogout": (p: { sessionId: string }) => [
    "up",
    "delete",
    `/settings/personal/sessions/${p.sessionId}`,
  ],
  "up.beta.on": (p: { teamDomain: string }) => ["up", "put", `/settings/teams/${p.teamDomain}/beta/on`],
  "up.beta.off": (p: { teamDomain: string }) => ["up", "put", `/settings/teams/${p.teamDomain}/beta/off`],
  "up.settings.general.setup": (p: { teamDomain: string }) => ["up", "get", `/settings/teams/${p.teamDomain}/setup`],
  "up.settings.general.update": (p: { teamDomain: string }) => ["up", "put", `/settings/teams/${p.teamDomain}`],

  "up.reporting.usage": (p: { teamDomain: string }) => ["up", "get", `/usage/${p.teamDomain}/index`],
  "up.visitorData.index": (p: { teamDomain: string }) => [
    "up",
    "get",
    `/settings/teams/${p.teamDomain}/visitor-data/index`,
  ],
  "up.visitorData.delete": (p: { teamDomain: string; visitorId: string }) => [
    "up",
    "delete",
    `/settings/teams/${p.teamDomain}/visitor-data/${p.visitorId}`,
  ],
  "up.members.index": (p: { teamDomain: string }) => ["up", "get", `/settings/teams/${p.teamDomain}/members/index`],
  "up.members.invite.index": (p: { teamDomain: string }) => [
    "up",
    "get",
    `/settings/teams/${p.teamDomain}/members/invites/index`,
  ],
  "up.members.delete": (p: { teamDomain: string; memberId: string }) => [
    "up",
    "delete",
    `/settings/teams/${p.teamDomain}/members/${p.memberId}`,
  ],
  "up.members.resetPassword": (p: { teamDomain: string; memberId: string }) => [
    "up",
    "delete",
    `/settings/teams/${p.teamDomain}/members/${p.memberId}/password`,
  ],
  "up.members.resetMfa": (p: { teamDomain: string; memberId: string }) => [
    "up",
    "delete",
    `/settings/teams/${p.teamDomain}/members/${p.memberId}/mfa`,
  ],
  "up.members.logout": (p: { teamDomain: string; memberId: string }) => [
    "up",
    "delete",
    `/settings/teams/${p.teamDomain}/members/${p.memberId}/session`,
  ],
  "up.members.invite": (p: { teamDomain: string }) => ["up", "post", `/settings/teams/${p.teamDomain}/members/invites`],
  "up.members.invite.resend": (p: { teamDomain: string; inviteId: string }) => [
    "up",
    "post",
    `/settings/teams/${p.teamDomain}/members/invites/${p.inviteId}/resend`,
  ],
  "up.members.invite.revoke": (p: { teamDomain: string; inviteId: string }) => [
    "up",
    "delete",
    `/settings/teams/${p.teamDomain}/members/invites/${p.inviteId}`,
  ],
  "up.members.permissions.update": (p: { teamDomain: string; memberId: string }) => [
    "up",
    "put",
    `/settings/teams/${p.teamDomain}/members/${p.memberId}`,
  ],
  "up.members.invite.permissions.update": (p: { teamDomain: string; inviteId: string }) => [
    "up",
    "put",
    `/settings/teams/${p.teamDomain}/members/invites/${p.inviteId}`,
  ],
  "up.members.transferOwnership": (p: { teamDomain: string; memberId: string }) => [
    "up",
    "post",
    `/settings/teams/${p.teamDomain}/members/${p.memberId}/transfer`,
  ],
  "up.billing.history.details": (p: { teamDomain: string }) => [
    "up",
    "get",
    `/settings/teams/${p.teamDomain}/billing/history/details`,
  ],
  "up.billing.data": (p: { teamDomain: string }) => ["up", "get", `/settings/teams/${p.teamDomain}/billing/data`],
  "up.billing.link": (p: { teamDomain: string }) => ["up", "post", `/settings/teams/${p.teamDomain}/billing/link`],
  "up.billing.unlink": (p: { teamDomain: string }) => ["up", "delete", `/settings/teams/${p.teamDomain}/billing/link`],
  "up.billing.card.intent": (p: { teamDomain: string }) => [
    "up",
    "get",
    `/settings/teams/${p.teamDomain}/billing/card/token`,
  ],
  "up.billing.card.update": (p: { teamDomain: string; stripeToken: string }) => [
    "up",
    "post",
    `/settings/teams/${p.teamDomain}/billing/card`,
  ],
  "up.billing.licenses.update": (p: { teamDomain: string; activeLicenses: number; autoAdjustLicenses: string }) => [
    "up",
    "put",
    `/settings/teams/${p.teamDomain}/billing/licenses`,
  ],
  "up.billing.subscription.cancel": (p: { teamDomain: string }) => [
    "up",
    "delete",
    `/settings/teams/${p.teamDomain}/billing/subscription`,
  ],
  "up.billing.subscription.resume": (p: { teamDomain: string }) => [
    "up",
    "put",
    `/settings/teams/${p.teamDomain}/billing/subscription/on`,
  ],
  "up.billing.update": (p: { teamDomain: string }) => ["up", "put", `/settings/teams/${p.teamDomain}/billing/details`],
  "up.billing.sessionCredit.buy": (p: { teamDomain: string }) => [
    "up",
    "post",
    `/settings/teams/${p.teamDomain}/billing/session-credit/buy`,
  ],
  "up.billing.sessionCredit.settings.update": (p: { teamDomain: string }) => [
    "up",
    "put",
    `/settings/teams/${p.teamDomain}/billing/session-credit/settings`,
  ],
  "up.settings.integrations.intercom.ssoOff": (p: { teamDomain: string; integrationId: string }) => [
    "up",
    "post",
    `/settings/teams/${p.teamDomain}/integrations/intercom/${p.integrationId}/sso/off`,
  ],
  "up.settings.integrations.intercom.customize": (p: { teamDomain: string; integrationId: string }) => [
    "up",
    "put",
    `/settings/teams/${p.teamDomain}/integrations/intercom/${p.integrationId}/settings`,
  ],
  "up.settings.integrations.intercom.ssoOn": (p: { teamDomain: string; integrationId: string }) => [
    "up",
    "post",
    `/settings/teams/${p.teamDomain}/integrations/intercom/${p.integrationId}/sso/on`,
  ],
  "up.settings.integrations.intercom.remove": (p: { teamDomain: string; integrationId: string }) => [
    "up",
    "delete",
    `/settings/teams/${p.teamDomain}/integrations/intercom/${p.integrationId}`,
  ],
  "up.settings.integrations.google.ssoOff": (p: { teamDomain: string; integrationId: string }) => [
    "up",
    "post",
    `/settings/teams/${p.teamDomain}/integrations/google_oauth2/${p.integrationId}/sso/off`,
  ],
  "up.settings.integrations.google.ssoOn": (p: { teamDomain: string; integrationId: string }) => [
    "up",
    "post",
    `/settings/teams/${p.teamDomain}/integrations/google_oauth2/${p.integrationId}/sso/on`,
  ],
  "up.settings.integrations.google.remove": (p: { teamDomain: string; integrationId: string }) => [
    "up",
    "delete",
    `/settings/teams/${p.teamDomain}/integrations/google_oauth2/${p.integrationId}`,
  ],
  "up.settings.integrations.salesforce.setup": (p: { teamDomain: string; sandbox: boolean }) => [
    "up",
    "get",
    `/settings/teams/${p.teamDomain}/integrations/add/salesforce${p.sandbox ? "?sandbox=true" : ""}`,
  ],
  "up.settings.integrations.salesforce.ssoOff": (p: { teamDomain: string; integrationId: string }) => [
    "up",
    "post",
    `/settings/teams/${p.teamDomain}/integrations/salesforce/${p.integrationId}/sso/off`,
  ],
  "up.settings.integrations.salesforce.ssoOn": (p: { teamDomain: string; integrationId: string }) => [
    "up",
    "post",
    `/settings/teams/${p.teamDomain}/integrations/salesforce/${p.integrationId}/sso/on`,
  ],
  "up.settings.integrations.salesforce.remove": (p: { teamDomain: string; integrationId: string }) => [
    "up",
    "delete",
    `/settings/teams/${p.teamDomain}/integrations/salesforce/${p.integrationId}`,
  ],
  "up.settings.integrations.salesforce.customize": (p: { teamDomain: string; integrationId: string }) => [
    "up",
    "put",
    `/settings/teams/${p.teamDomain}/integrations/salesforce/${p.integrationId}/settings`,
  ],
  "up.settings.integrations.zapier.setup": (p: { teamDomain: string }) => [
    "up",
    "post",
    `/settings/teams/${p.teamDomain}/integrations/add/zapier`,
  ],
  "up.settings.integrations.zapier.remove": (p: { teamDomain: string; integrationId: string }) => [
    "up",
    "delete",
    `/settings/teams/${p.teamDomain}/integrations/zapier/${p.integrationId}`,
  ],
  "up.settings.integrations.zendesk.setup": (p: { teamDomain: string }) => [
    "up",
    "post",
    `/settings/teams/${p.teamDomain}/integrations/add/zendesk`,
  ],
  "up.settings.integrations.zendesk.remove": (p: { teamDomain: string; integrationId: string }) => [
    "up",
    "delete",
    `/settings/teams/${p.teamDomain}/integrations/zendesk/${p.integrationId}`,
  ],
  "up.settings.integrations.freshchat.setup": (p: { teamDomain: string }) => [
    "up",
    "post",
    `/settings/teams/${p.teamDomain}/integrations/add/freshchat`,
  ],
  "up.settings.integrations.freshchat.remove": (p: { teamDomain: string; integrationId: string }) => [
    "up",
    "delete",
    `/settings/teams/${p.teamDomain}/integrations/freshchat/${p.integrationId}`,
  ],
  "up.settings.integrations.twilioFlex.setup": (p: { teamDomain: string }) => [
    "up",
    "post",
    `/settings/teams/${p.teamDomain}/integrations/add/twilio_flex`,
  ],
  "up.settings.integrations.twilioFlex.remove": (p: { teamDomain: string; integrationId: string }) => [
    "up",
    "delete",
    `/settings/teams/${p.teamDomain}/integrations/twilio_flex/${p.integrationId}`,
  ],
  "up.settings.integrations.front.setup": (p: { teamDomain: string }) => [
    "up",
    "post",
    `/settings/teams/${p.teamDomain}/integrations/add/front`,
  ],
  "up.settings.integrations.front.remove": (p: { teamDomain: string; integrationId: string }) => [
    "up",
    "delete",
    `/settings/teams/${p.teamDomain}/integrations/front/${p.integrationId}`,
  ],
} as const satisfies Record<string, (p: any) => ["up", "get" | "delete" | "post" | "put", `/${string}`]>;

export const settingsRequests = {
  "up.settings.personal.setup": z.undefined(),
  "up.settings.personal.update": z.object({
    user: User.pick({
      fromOauth: true,
      name: true,
      email: true,
      phoneNumber: true,
      subscribedToEmails: true,
      username: true,
      settingCallAgentNotification: true,
      language: true,
    }).partial(),
  }),
  "up.settings.personal.password.update": z.object({
    user: z.object({
      password: z.string(),
    }),
  }),
  "up.settings.personal.password.disable": z.undefined(),
  "up.settings.personal.mfa.disable": z.undefined(),
  "up.settings.personal.remoteLogout": z.object({
    sessionId: z.string(),
  }),
  "up.beta.on": z.object({
    teamDomain: z.string(),
  }),
  "up.beta.off": z.object({
    teamDomain: z.string(),
  }),
  "up.settings.general.setup": z.object({
    teamDomain: z.string(),
  }),
  "up.settings.general.update": z.object({
    teamDomain: z.string(),
    team: z.object({
      settings: z.object({
        settings: z.custom<Partial<TeamSettings>>().optional(),
        onPremise: z.custom<Partial<TeamOnPremiseSettings>>().optional(),
      }),
    }),
  }),
  "up.reporting.usage": z.object({
    teamDomain: z.string(),
    from: z.coerce.date(),
    to: z.coerce.date(),
  }),
  "up.visitorData.index": z.object({
    teamDomain: z.string(),
    search: z.string(),
  }),
  "up.visitorData.delete": z.object({
    teamDomain: z.string(),
    visitorId: z.string(),
  }),
  "up.members.index": z.object({
    teamDomain: z.string(),
    page: z.number().optional().nullable(),
    search: z.string().optional().nullable(),
  }),
  "up.members.invite.index": z.object({
    teamDomain: z.string(),
    page: z.number().optional().nullable(),
  }),
  "up.members.delete": z.object({
    teamDomain: z.string(),
    memberId: z.string(),
  }),
  "up.members.resetPassword": z.object({
    teamDomain: z.string(),
    memberId: z.string(),
  }),
  "up.members.resetMfa": z.object({
    teamDomain: z.string(),
    memberId: z.string(),
  }),
  "up.members.logout": z.object({
    teamDomain: z.string(),
    memberId: z.string(),
  }),
  "up.members.invite": z.object({
    teamDomain: z.string(),
    email: z.string(),
  }),
  "up.members.invite.resend": z.object({
    teamDomain: z.string(),
    inviteId: z.string(),
  }),
  "up.members.transferOwnership": z.object({
    teamDomain: z.string(),
    memberId: z.string(),
  }),
  "up.members.invite.revoke": z.object({
    teamDomain: z.string(),
    inviteId: z.string(),
  }),
  "up.members.permissions.update": z.object({
    permissions: z.object({
      canAccessVisitorsList: z.boolean().optional(),
      canScreenshare: z.boolean().optional(),
      canAccessSettings: z.boolean().optional(),
      canManageUsers: z.boolean().optional(),
      canManageBilling: z.boolean().optional(),
      canAccessActivityLog: z.boolean().optional(),
      canUseConsole: z.boolean().optional(),
      canDeleteVisitorData: z.boolean().optional(),
      canViewReporting: z.boolean().optional(),
      canPurchase: z.boolean().optional(),
    }),
    teamDomain: z.string(),
    memberId: z.string(),
  }),
  "up.members.invite.permissions.update": z.object({
    permissions: z.object({
      canAccessVisitorsList: z.boolean().optional(),
      canScreenshare: z.boolean().optional(),
      canAccessSettings: z.boolean().optional(),
      canManageUsers: z.boolean().optional(),
      canManageBilling: z.boolean().optional(),
      canAccessActivityLog: z.boolean().optional(),
      canUseConsole: z.boolean().optional(),
      canDeleteVisitorData: z.boolean().optional(),
      canViewReporting: z.boolean().optional(),
      canPurchase: z.boolean().optional(),
    }),
    teamDomain: z.string(),
    inviteId: z.string(),
  }),
  "up.billing.history.details": z.object({
    teamDomain: z.string(),
    page: z.number().optional().nullable(),
  }),
  "up.billing.data": z.object({
    teamDomain: z.string(),
  }),
  "up.billing.link": z.object({
    teamDomain: z.string(),
    subaccountId: z.string(),
  }),
  "up.billing.unlink": z.object({
    teamDomain: z.string(),
    subaccountId: z.string(),
  }),
  "up.billing.card.intent": z.object({
    teamDomain: z.string(),
  }),
  "up.billing.card.update": z.object({
    teamDomain: z.string(),
    intentTokenId: z.string(),
  }),
  "up.billing.licenses.update": z.object({
    teamDomain: z.string(),
    activeLicenses: z.number().optional(),
    autoAdjustLicenses: z.string().optional(),
  }),
  "up.billing.subscription.cancel": z.object({
    teamDomain: z.string(),
    cancelReason: z.string(),
    cancelDetails: z.string().optional(),
  }),
  "up.billing.subscription.resume": z.object({
    teamDomain: z.string(),
  }),
  "up.billing.update": z.object({
    teamDomain: z.string(),
    vatNumber: z.string().optional().nullable(),
    invoiceName: z.string().optional().nullable(),
    invoiceAddress: z.string().optional().nullable(),
    invoiceCountry: CountryCode.nullable(),
    invoiceZip: z.string().optional().nullable(),
    invoiceEmail: z.string().optional().nullable(),
  }),
  "up.billing.sessionCredit.buy": z.object({
    teamDomain: z.string(),
    hours: z.number(),
  }),
  "up.billing.sessionCredit.settings.update": z.object({
    teamDomain: z.string(),
    team: TeamBillingInfoData.pick({
      settingSessionTimeCreditAutoTopUpEnabled: true,
      settingSessionTimeCreditAutoTopUpTrigger: true,
      settingSessionTimeCreditAutoTopUpMultiplier: true,
    }),
  }),
  "up.settings.integrations.intercom.ssoOff": z.object({
    teamDomain: z.string(),
    integrationId: z.string(),
  }),
  "up.settings.integrations.intercom.ssoOn": z.object({
    teamDomain: z.string(),
    integrationId: z.string(),
  }),
  "up.settings.integrations.intercom.remove": z.object({
    teamDomain: z.string(),
    integrationId: z.string(),
  }),
  "up.settings.integrations.intercom.customize": z.object({
    teamDomain: z.string(),
    integrationId: z.string(),
    intercomIntegration: z
      .object({
        settingPromptTitle: z.string(),
        settingPromptMessage: z.string(),
        settingPromptAcceptText: z.string(),
        settingPromptRefuseText: z.string(),
        settingPromptExplanation: z.string(),
        settingStartMessage: z.string(),
        settingAddSessionSummaryToIntercom: z.boolean().default(true),
      })
      .partial(),
  }),
  "up.settings.integrations.google.ssoOff": z.object({
    teamDomain: z.string(),
    integrationId: z.string(),
  }),
  "up.settings.integrations.google.ssoOn": z.object({
    teamDomain: z.string(),
    integrationId: z.string(),
  }),
  "up.settings.integrations.google.remove": z.object({
    teamDomain: z.string(),
    integrationId: z.string(),
  }),
  "up.settings.integrations.salesforce.setup": z.object({
    teamDomain: z.string(),
    sandbox: z.boolean().optional(),
  }),
  "up.settings.integrations.salesforce.customize": z.object({
    teamDomain: z.string(),
    integrationId: z.string(),
    salesforceIntegration: z
      .object({
        settingFieldMapping: z.string(),
        settingCreateCallFromSession: z.boolean().default(false),
      })
      .partial(),
  }),
  "up.settings.integrations.salesforce.ssoOff": z.object({
    teamDomain: z.string(),
    integrationId: z.string(),
  }),
  "up.settings.integrations.salesforce.ssoOn": z.object({
    teamDomain: z.string(),
    integrationId: z.string(),
  }),
  "up.settings.integrations.salesforce.remove": z.object({
    teamDomain: z.string(),
    integrationId: z.string(),
  }),
  "up.settings.integrations.zapier.setup": z.object({
    teamDomain: z.string(),
  }),
  "up.settings.integrations.zapier.remove": z.object({
    teamDomain: z.string(),
    integrationId: z.string(),
  }),
  "up.settings.integrations.zendesk.setup": z.object({
    teamDomain: z.string(),
  }),
  "up.settings.integrations.zendesk.remove": z.object({
    teamDomain: z.string(),
    integrationId: z.string(),
  }),
  "up.settings.integrations.freshchat.setup": z.object({
    teamDomain: z.string(),
  }),
  "up.settings.integrations.freshchat.remove": z.object({
    teamDomain: z.string(),
    integrationId: z.string(),
  }),
  "up.settings.integrations.front.setup": z.object({
    teamDomain: z.string(),
    apiKey: z.string(),
  }),
  "up.settings.integrations.front.remove": z.object({
    teamDomain: z.string(),
    integrationId: z.string(),
  }),
  "up.settings.integrations.twilioFlex.setup": z.object({
    teamDomain: z.string(),
  }),
  "up.settings.integrations.twilioFlex.remove": z.object({
    teamDomain: z.string(),
    integrationId: z.string(),
  }),
} as const;

export const settingsResponses = {
  "up.settings.personal.setup": SuccessResponse.extend({
    user: User.pick({
      name: true,
      email: true,
      phoneNumber: true,
      subscribedToEmails: true,
      username: true,
      settingCallAgentNotification: true,
      language: true,
      fromOauth: true,
      passwordEnableNeeded: true,
      password: true,
      mfaEnabled: true,
    }),
    sessions: z
      .object({
        id: z.string(),
        device: z.string(),
        lastSeenAt: z.coerce.date().nullable(),
      })
      .array(),
    currentSessionId: z.string(),
    allowPassword: z.boolean(),
  }),
  "up.settings.personal.update": SuccessResponse.extend({ checkEmail: z.boolean() }).or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      userErrors: zNoEmptyObject(
        z.object({ name: z.string(), email: z.string(), phoneNumber: z.string(), username: z.string() }),
      ),
    }).or(
      ErrorResponse.extend({
        error: z.literal("admin_cannot_change_email"),
      }),
    ),
  ),
  "up.settings.personal.password.update": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      userErrors: zNoEmptyObject(z.object({ password: z.string() })),
    }).or(
      ErrorResponse.extend({
        error: z.literal("oauth_user_cannot_change_password"),
      }),
    ),
  ),
  "up.settings.personal.password.disable": SuccessResponse,
  "up.settings.personal.mfa.disable": SuccessResponse,
  "up.settings.personal.remoteLogout": SuccessResponse,
  "up.beta.on": SuccessResponse,
  "up.beta.off": SuccessResponse,
  "up.settings.general.setup": SuccessResponse.extend({
    settings: z.object({
      settings: z.custom<TeamSettings>(),
      onPremiseSettings: z.custom<TeamOnPremiseSettings>().nullable(),
    }),
    features: Feature.array(),
    products: Product.array(),
    beta: z.boolean(),
  }),
  "up.settings.general.update": SuccessResponse.extend({
    settings: z.object({
      settings: z.custom<TeamSettings>(),
      onPremiseSettings: z.custom<TeamOnPremiseSettings>().nullable(),
    }),
  }).or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      validationErrors: z.record(z.string(), z.string()),
    }),
  ),
  "up.reporting.usage": SuccessResponse.extend({
    usage: CobrowsingTeamUsage,
    previousUsage: CobrowsingTeamUsage,
  }).or(
    ErrorResponse.extend({
      error: z.literal("record_not_found"),
    }),
  ),
  "up.visitorData.index": SuccessResponse.extend({
    visitors: VisitorModel.array(),
  }).or(
    ErrorResponse.extend({
      error: z.literal("server_unavailable"),
    }),
  ),
  "up.visitorData.delete": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.enum(["server_unavailable", "visitor_not_deletable"]),
    }),
  ),
  "up.members.index": SuccessResponse.extend({
    pagy: Pagy,
    memberships: TeamMembershipTeamManagementData.array(),
    currentUserMembership: TeamMembershipTeamManagementData,
  }),
  "up.members.invite.index": SuccessResponse.extend({
    pagy: Pagy,
    invites: Invite.array(),
    currentUserMembership: TeamMembershipTeamManagementData,
    signupOptions: z.object({
      link: Url,
      options: z.object({
        saml: z.boolean(),
        email: z.string().nullable(),
        sso: z.boolean(),
      }),
    }),
  }),
  "up.members.permissions.update": SuccessResponse,
  "up.members.invite.permissions.update": SuccessResponse,
  "up.members.logout": SuccessResponse,
  "up.members.delete": SuccessResponse,
  "up.members.resetPassword": SuccessResponse,
  "up.members.resetMfa": SuccessResponse,
  "up.members.invite.resend": SuccessResponse.extend({
    invite: Invite,
    removeId: z.string(),
  }),
  "up.members.invite.revoke": SuccessResponse,
  "up.members.transferOwnership": SuccessResponse,
  "up.members.invite": SuccessResponse.extend({
    invite: Invite,
  }).or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      inviteErrors: z.object({
        email: z.string(),
      }),
    }),
  ),
  "up.billing.history.details": SuccessResponse.extend({
    teamInvoices: Invoice.array(),
    teamCharges: Charge.array(),
  }),
  "up.billing.data": SuccessResponse.extend({
    team: TeamBillingInfoData,
    subaccounts: TeamBillingInfoData.array(),
    possibleBillingOwners: TeamBillingInfoData.array(),
  }),
  "up.billing.link": SuccessResponse,
  "up.billing.unlink": SuccessResponse,
  "up.billing.card.intent": SuccessResponse.extend({
    intentToken: z.object({
      id: z.string(),
      clientSecret: z.string(),
      publicKey: z.string(),
    }),
  }),
  "up.billing.card.update": SuccessResponse.extend({
    source: PaymentSourceData,
  }),
  "up.billing.licenses.update": SuccessResponse.extend({
    team: TeamBillingInfoData,
  }),
  "up.billing.subscription.cancel": SuccessResponse.extend({
    team: TeamBillingInfoData,
  }),
  "up.billing.subscription.resume": SuccessResponse.extend({
    team: TeamBillingInfoData,
  }),
  "up.billing.update": SuccessResponse.extend({
    invoiceDetails: z.object({
      invoiceAddress: z.string().nullable(),
      invoiceCountry: z.string().nullable(),
      invoiceEmail: z.string().nullable(),
      invoiceName: z.string().nullable(),
      invoiceZip: z.string().nullable(),
      vatNumber: z.string().nullable(),
    }),
  }).or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      teamErrors: z.record(settingsRequests["up.billing.update"].keyof(), z.string()),
    }),
  ),
  "up.billing.sessionCredit.buy": SuccessResponse.extend({
    team: TeamBillingInfoData,
  }),
  "up.billing.sessionCredit.settings.update": SuccessResponse.extend({
    team: TeamBillingInfoData,
  }),
  "up.settings.integrations.intercom.ssoOff": SuccessResponse,
  "up.settings.integrations.intercom.ssoOn": SuccessResponse,
  "up.settings.integrations.intercom.customize": SuccessResponse,
  "up.settings.integrations.intercom.remove": SuccessResponse,
  "up.settings.integrations.google.ssoOff": SuccessResponse,
  "up.settings.integrations.google.ssoOn": SuccessResponse,
  "up.settings.integrations.google.remove": SuccessResponse,
  "up.settings.integrations.salesforce.setup": SuccessResponse,
  "up.settings.integrations.salesforce.ssoOff": SuccessResponse,
  "up.settings.integrations.salesforce.ssoOn": SuccessResponse,
  "up.settings.integrations.salesforce.remove": SuccessResponse,
  "up.settings.integrations.salesforce.customize": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      integrationErrors: zNoEmptyObject(z.object({ settingFieldMapping: z.string() })),
    }),
  ),
  "up.settings.integrations.zapier.setup": SuccessResponse.extend({
    integration: ZapierIntegration,
  }),
  "up.settings.integrations.zapier.remove": SuccessResponse,
  "up.settings.integrations.zendesk.setup": SuccessResponse.extend({
    integration: ZendeskIntegration,
  }),
  "up.settings.integrations.zendesk.remove": SuccessResponse,
  "up.settings.integrations.freshchat.setup": SuccessResponse.extend({
    integration: FreshchatIntegration,
  }),
  "up.settings.integrations.freshchat.remove": SuccessResponse,
  "up.settings.integrations.front.setup": SuccessResponse.extend({
    integration: FrontIntegration,
  }).or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      integrationErrors: zNoEmptyObject(z.object({ apiKey: z.string() })),
    }),
  ),
  "up.settings.integrations.front.remove": SuccessResponse,
  "up.settings.integrations.twilioFlex.setup": SuccessResponse.extend({
    integration: TwilioFlexIntegration,
  }),
  "up.settings.integrations.twilioFlex.remove": SuccessResponse,
} as const;
