import { forwardRef, useEffect } from "react";
import { useMeasure } from "@react-hookz/web";

import useCombinedRefs from "../../use/combined-refs";
import { classNames, wrapText } from "../../utils";
import { scrollErrorIntoView } from "../forms/FormInput";

import LoadingSpinner from "./LoadingSpinner";

type Props = {
  disabled?: boolean;
  loading?: boolean;
  children?: React.ReactNode;
  variant?: "primary" | "secondary" | "action" | "text";
  color?: "red" | "green" | "black";
  type?: "button" | "submit" | "reset";
  size?: "xs" | "sm" | "md" | "lg";
  className?: string;
  progressPercent?: number;
} & JSX.IntrinsicElements["button"];

const hoverShadow = "hover:shadow-lg";
const hoverScale = "hover:scale-[--max-scale] active:scale-[--min-scale]";

export function buttonClassName(props: Pick<Props, "loading" | "size" | "variant" | "className" | "color">) {
  const variant = props.variant ?? "primary";
  const color = props.color ?? "black";

  let background, border, textColor;

  switch (`${variant}-${color}`) {
    case "primary-black":
      background = `bg-black dark:bg-white ${hoverShadow} ${hoverScale}`;
      border = "border-2 border-black dark:border-white";
      textColor = "text-white dark:text-black";
      break;
    case "action-black":
      background = "bg-white dark:bg-gray-700 hover:bg-gray-50 hover:dark:bg-gray-600";
      border = "shadow-sm border border-gray-300 dark:border-none";
      textColor = "text-gray-700 dark:text-gray-200";
      break;
    case "secondary-black":
      background = `bg-gray-200 dark:bg-white/70 ${hoverShadow} ${hoverScale}`;
      border = "shadow-sm";
      textColor = "text-black/70";
      break;
    case "text-black":
      background = hoverScale;
      textColor = "text-black dark:text-white";
      break;
    case "primary-red":
      background = `bg-red-500 hover:bg-red-600 ${hoverShadow} ${hoverScale}`;
      border = "border-2 border-red-500 hover:border-red-600";
      textColor = "text-white";
      break;
    case "action-red":
      background = "bg-white dark:bg-gray-700 hover:bg-gray-50 hover:dark:bg-gray-600";
      border = "shadow-sm border border-gray-300 dark:border-none";
      textColor = "text-red-700 dark:text-red-500";
      break;
    case "secondary-red":
      background = `bg-red-100 ${hoverShadow} ${hoverScale}`;
      textColor = "text-red-700";
      break;
    case "text-red":
      background = hoverScale;
      textColor = "text-red-500";
      break;
    case "primary-green":
      background = `bg-green-500 hover:bg-green-600 ${hoverShadow} ${hoverScale}`;
      border = "border-2 border-green-500 hover:border-green-600";
      textColor = "text-white";
      break;
    case "action-green":
      background = "bg-white dark:bg-gray-700 hover:bg-gray-50 hover:dark:bg-gray-600";
      border = "shadow-sm border border-gray-300 dark:border-none";
      textColor = "text-green-700 dark:text-green-300";
      break;
    case "secondary-green":
      background = `bg-green-100 ${hoverShadow} ${hoverScale}`;
      textColor = "text-green-700";
      break;
    case "text-green":
      background = hoverScale;
      textColor = "text-green-700 dark:text-green-400";
      break;
  }

  return classNames(
    `inline-flex relative text-center justify-center items-center gap-3 text-xs font-semibold tracking-widest
    whitespace-nowrap cursor-pointer !no-underline`,
    "uppercase transition-all duration-200 ease-in-out",
    background,
    border,
    textColor,
    props.loading &&
      "cursor-default opacity-60 [&>span]:text-transparent [&>svg:not([data-loader])]:text-transparent pointer-events-none",
    !props.loading && "disabled:cursor-not-allowed disabled:opacity-50 disabled:pointer-events-none",
    props.size === "xs" && "px-1 py-0.5 rounded [&>svg]:-mx-0.5 [&>svg]:w-3 [&>svg]:h-3 [&>svg]:-my-3",
    props.size === "sm" && "px-2 py-1.5 rounded [&>svg]:-mx-1 [&>svg]:w-4 [&>svg]:h-4 [&>svg]:-my-4",
    props.size === "md" && "px-4 py-2 rounded-md [&>svg]:-mx-1 [&>svg]:w-5 [&>svg]:h-5 [&>svg]:-my-5",
    props.size === "lg" && "px-6 py-3.5 rounded-full [&>svg]:-mx-2 [&>svg]:w-6 [&>svg]:h-6 [&>svg]:-my-6",
    props.className,
  );
}

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ disabled, loading, type, variant, children, size, className, color, progressPercent, ...props }: Props, ref) => {
    disabled ??= false;
    loading ??= false;
    size ??= "md";
    variant ??= "primary";
    type ??= "button";
    const [measure, measureRef] = useMeasure<HTMLButtonElement>(className?.includes("w-full") ?? false);
    const innerRef = useCombinedRefs(ref, measureRef);

    useEffect(() => {
      if (measure) {
        // Limit it to a 3px per side scale
        innerRef.current?.style.setProperty("--max-scale", ((measure.width + 6) / measure.width).toString());
        innerRef.current?.style.setProperty("--min-scale", ((measure.width - 6) / measure.width).toString());
      } else {
        innerRef.current?.style.setProperty("--max-scale", "1.05");
        innerRef.current?.style.setProperty("--min-scale", "0.95");
      }
    }, [measure, innerRef]);

    useEffect(() => {
      if (!loading && innerRef.current?.form) scrollErrorIntoView(innerRef.current.form);
    }, [loading, innerRef]);

    return (
      <button
        type={type}
        ref={innerRef}
        className={buttonClassName({ loading, size, variant, className, color })}
        disabled={disabled || loading}
        {...props}
      >
        {typeof progressPercent === "number" && (
          <div
            className="absolute left-0 h-0.5 bottom-0 transition-all bg-current"
            style={{ width: `${Math.min(progressPercent, 1) * 100}%` }}
          />
        )}
        {loading && <LoadingSpinner color="currentColor" />}
        {wrapText(children ?? "Submit")}
      </button>
    );
  },
);

export default Button;
