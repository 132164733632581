import type { Plan } from "../data/models/Plan";
import { Product } from "../data/models/partials/Product";

export function productName(identifier: Product): string {
  switch (identifier) {
    case "helloscreen":
      return "HelloScreen";
    case "userview":
      return "UserView";
    case "livedocument":
      return "LiveDocument";
    case "cobrowsingapi":
      return "Co-Browsing API";
  }
}

export function licenseType(type: Plan["licenseType"]): string {
  switch (type) {
    case "concurrent":
      return "Concurrent";
    case "agent":
      return "Named agent";
    case "seat":
      return "Seat";
  }
}
