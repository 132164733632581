import { __assign } from "tslib";
export var applyCaseOptions = function (fn, defaultOptions) {
    return function (input, options) {
        return fn(input, __assign(__assign({}, defaultOptions), options));
    };
};
export var preserveSpecificKeys = function (fn, keys) {
    var condition = typeof keys === 'function'
        ? keys
        : function (input) { return keys.includes(input); };
    return function (input, options) {
        return condition(input, options) ? input : fn(input, options);
    };
};
