import { z } from "zod";
import { Url } from "./partials/Url";

export const Invoice = z.object({
  id: z.string(),
  amountFormatted: z.string(),
  paid: z.boolean(),
  voided: z.boolean(),
  overdue: z.boolean(),
  date: z.coerce.date(),
  dueDate: z.coerce.date().nullable(),
  invoiced: z.boolean(),
  url: Url.nullable(),
  type: z.literal("invoice"),
});

export type Invoice = z.infer<typeof Invoice>;

export function invoiceStatusLabel(invoice: Invoice) {
  if (invoice.paid) {
    return "Paid";
  }
  if (invoice.voided) {
    return "Voided";
  }
  if (invoice.overdue) {
    return "Overdue";
  }
}
