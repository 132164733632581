import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

import t from "../../i18n";
import { useModal } from "../../providers/ModalProvider";

import Button from "./Button";

type Props = {
  title: string;
  children: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  disappearing?: boolean;
  loading?: boolean;
  defaultBehavior?: "confirm" | "cancel" | "none";
  type?: "warning" | "success" | "info";
  onClick?: (accept: boolean) => void;
};

export default function Modal(props: Props) {
  const type = props.type ?? "info";
  const buttonColor = type === "warning" ? "red" : type === "success" ? "green" : "black";
  const confirmText = props.confirmText ?? t("Ok");
  const defaultBehavior = props.defaultBehavior ?? "cancel";

  const cancelButtonRef = useRef(null);
  const confirmButtonRef = useRef(null);
  const titleRef = useRef(null);
  const { removeModal } = useModal();

  function onCancel() {
    props.onClick?.(false);
  }

  function onConfirm() {
    props.onClick?.(true);
  }

  function onClose() {
    switch (defaultBehavior) {
      case "confirm":
        onConfirm();
        break;
      case "cancel":
        onCancel();
        break;
      case "none":
        removeModal();
        break;
    }
  }

  const initialFocus =
    defaultBehavior === "confirm" ? confirmButtonRef : defaultBehavior === "cancel" ? cancelButtonRef : titleRef;

  return (
    <Transition.Root show={!props.disappearing} as={Fragment}>
      <Dialog as="div" className="relative z-70" initialFocus={initialFocus} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                data-role="modal"
                className="relative transform overflow-hidden rounded-lg bg-white dark:bg-neutral-800 text-left shadow-xl transition-all sm:my-8
                  w-full sm:max-w-lg"
              >
                <div className="p-5 text-slate-700 dark:text-slate-100 space-y-2 text-center">
                  <Dialog.Title className="text-xl font-semibold" ref={titleRef}>
                    {props.title}
                  </Dialog.Title>
                  {typeof props.children === "string" ? (
                    <Dialog.Description>{props.children}</Dialog.Description>
                  ) : (
                    props.children
                  )}
                </div>
                <div className="bg-neutral-100 dark:bg-black/60 p-3 flex flex-col sm:flex-row-reverse gap-3 justify-items-stretch">
                  <Button
                    color={buttonColor}
                    className="w-full"
                    onClick={onConfirm}
                    loading={props.loading}
                    ref={confirmButtonRef}
                  >
                    {confirmText}
                  </Button>
                  {props.cancelText && (
                    <Button
                      className="w-full"
                      ref={cancelButtonRef}
                      onClick={onCancel}
                      variant={buttonColor === "black" ? "secondary" : "primary"}
                    >
                      {props.cancelText}
                    </Button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
