import { createContext, useCallback, useContext, useRef, useState } from "react";

import { Notification, NotificationsContainer } from "../components/ui/Notifications";
import { uuid } from "../utils";

export type NotificationDetails = {
  id: string;
  title?: string;
  message: string;
  disappearing: boolean;
  type?: "error" | "success";
  action?: {
    label: string;
    onClick: () => void;
  };
};

type ProviderProps = {
  children: JSX.Element;
};

const NotificationsContext = createContext(
  (notification: Omit<NotificationDetails, "id" | "disappearing">) => () => {},
);

export const NOTIFICATION_TTL = 8000;

export function useNotifications() {
  return useContext(NotificationsContext);
}

export function NotificationsProvider({ children }: ProviderProps): JSX.Element {
  const [notifications, setNotifications] = useState<NotificationDetails[]>([]);
  const notificationRef = useRef<NotificationDetails[]>([]);
  notificationRef.current = notifications;

  const removeNotification = useCallback((id: string): void => {
    setNotifications(notificationRef.current.map((n) => (n.id === id ? { ...n, disappearing: true } : n)));
    setTimeout(() => {
      setNotifications(notificationRef.current.filter((n) => n.id !== id));
    }, 1000);
  }, []);

  const addNotification = useCallback(
    (notification: Omit<NotificationDetails, "id" | "disappearing">): (() => void) => {
      const id = uuid();
      setNotifications((notifications) => [...notifications, { ...notification, id, disappearing: false }]);
      if (notificationRef.current.length > 1) removeNotification(notificationRef.current[1]!.id);

      setTimeout(() => {
        removeNotification(id);
      }, NOTIFICATION_TTL);

      return () => {
        removeNotification(id);
      };
    },
    [removeNotification],
  );

  return (
    <NotificationsContext.Provider value={addNotification}>
      <NotificationsContainer>
        {notifications.map((n) => (
          <Notification key={n.id} {...n} onClose={() => removeNotification(n.id)} />
        ))}
      </NotificationsContainer>
      {children}
    </NotificationsContext.Provider>
  );
}
