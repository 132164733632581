import React from "react";
import { z } from "zod";

import t from "./i18n";

export function presence<T>(value: T | null | undefined | ""): T | undefined {
  if (!isPresent(value)) return undefined;

  return value;
}

export function isPresent<T>(value: T | null | undefined | ""): value is T {
  return value !== null && typeof value !== "undefined" && value !== "";
}

export function uuid(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];
export type ContainsAllCheck<A, B> = [A] extends [B] ? ([B] extends [A] ? true : Exclude<B, A>) : Exclude<A, B>;

export function zNoEmptyObject<T extends z.SomeZodObject>(zodType: T) {
  return zodType
    .partial()
    .refine<
      AtLeastOne<z.infer<T>>
    >((val: Record<any, any>): val is AtLeastOne<z.infer<T>> => Object.keys(val).length > 0, {
      message: "Object cannot be empty",
    });
}

export function classNames(...classes: Array<string | undefined | null | false>): string {
  return classes.filter(Boolean).join(" ");
}

export function totalZIndex(element: HTMLElement): number {
  let zIndex = 0;
  let el: HTMLElement | null = element;
  while (el) {
    const zIndexValue = parseInt(getComputedStyle(el).zIndex || "0");
    if (zIndexValue) zIndex += zIndexValue;

    el = el.parentElement;
  }
  return zIndex;
}

export function matchChild(children: React.ReactNode, childType: React.ElementType | React.ElementType[]) {
  return React.Children.toArray(children).some(
    (child) =>
      React.isValidElement(child) &&
      (Array.isArray(childType) ? childType.includes(child.type as any) : child.type === childType),
  );
}

export function matchAllChildren(children: React.ReactNode, childType: React.ElementType | React.ElementType[]) {
  return React.Children.toArray(children).every(
    (child) =>
      React.isValidElement(child) &&
      (Array.isArray(childType) ? childType.includes(child.type as any) : child.type === childType),
  );
}

export function compact<T>(record: Record<string, T | undefined>): Record<string, T> {
  return Object.fromEntries(Object.entries(record).filter(([, value]) => typeof value !== "undefined")) as Record<
    string,
    T
  >;
}

export function addProps<T extends React.ElementType>(
  children: React.ReactNode,
  childType: T | T[],
  props:
    | Partial<React.ComponentProps<T>>
    | ((existingProps: Partial<React.ComponentProps<T>>, index: number) => Partial<React.ComponentProps<T>>),
): React.ReactNode {
  // If it's only 1 of type fragment, run function on its children
  if (React.Children.count(children) === 1 && React.isValidElement(children) && children.type === React.Fragment)
    return addProps(children.props.children, childType, props);

  return React.Children.map(children, (child, index) => {
    if (
      React.isValidElement(child) &&
      (Array.isArray(childType) ? childType.includes(child.type as any) : child.type === childType)
    ) {
      const newProps = typeof props === "function" ? props(child.props, index) : props;
      return React.cloneElement(child, { ...child.props, ...compact(newProps) });
    }
    return child;
  });
}

export function addClassNames<T extends React.ElementType>(
  children: React.ReactNode,
  childType: T | T[],
  className: string | ((index: number, total: number) => string),
): React.ReactNode {
  const count = React.Children.count(children);
  // If it's only 1 of type fragment, run function on its children
  if (React.Children.count(children) === 1 && React.isValidElement(children) && children.type === React.Fragment)
    return addClassNames(
      children.props.children,
      childType,
      typeof className === "string" ? className : className(0, count),
    );

  return React.Children.map(children, (child, index) => {
    if (
      React.isValidElement(child) &&
      (Array.isArray(childType) ? childType.includes(child.type as any) : child.type === childType)
    )
      return React.cloneElement(child, {
        ...child.props,
        className: classNames(
          child.props.className,
          typeof className === "string" ? className : className(index, count),
        ),
      });

    return child;
  });
}

export function wrapText(children: React.ReactNode) {
  // If it's only 1 of type fragment, run function on its children
  if (React.Children.count(children) === 1 && React.isValidElement(children) && children.type === React.Fragment)
    return wrapText(children.props.children);

  return React.Children.map(children, (child) => {
    if (typeof child === "string") return React.createElement("span", undefined, child);

    return child;
  });
}

export type TimeoutType = ReturnType<typeof setTimeout>;

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function matchesPattern(pattern: string, string: string): boolean {
  // Escape special characters, except for "*"
  pattern = pattern.replace(/[\\^$.+?()[\]{}|]/g, "\\$&");

  // Removes * at the beginning of the pattern, as they are slow
  pattern = pattern.startsWith("*") ? pattern.replace(/^\*+/, "") : `^${pattern}`;

  // Same with the end
  pattern = pattern.endsWith("*") ? pattern.replace(/\*+$/, "") : `${pattern}$`;

  return new RegExp(pattern.replace(/\*/g, ".*")).test(string);
}

export function containsFile(object: any): boolean {
  return Object.values(object)
    .flat()
    .some((x) => x instanceof File || (typeof x === "object" && x !== null ? containsFile(x) : false));
}
export function trimStrings<T extends Array<unknown> | Record<string, unknown> | string | unknown>(obj: T): T {
  if (Array.isArray(obj)) return obj.map(trimStrings) as T;

  if (typeof obj === "object" && obj !== null)
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, trimStrings(value)])) as T;

  if (typeof obj === "string") return obj.slice(0, 5) as T;

  return obj;
}

export function isPlainObject(obj: unknown): obj is Record<string, any> {
  if (typeof obj !== "object" || obj === null) return false;

  return Object.getPrototypeOf(obj) === Object.prototype;
}

export function intersperse<T extends any>(array: Array<T>, separator: string): Array<T | string> {
  if (array.length === 0) return [];

  const newArray = [array[0]!] as Array<T | string>;

  for (const item of array.slice(1)) newArray.push(separator, item);

  return newArray;
}

export function toSentence<T extends any>(array: Array<T>, oxford = true): Array<T | string> {
  if (array.length < 2) return array;

  if (array.length === 2) return [array[0]!, t(" and "), array[1]!];

  const last = array.pop()!;

  return [...intersperse(array, ",  "), oxford ? t(", and ") : t(" and "), last];
}
