import { useEffect } from 'react';
import { useFirstMountState } from '../useFirstMountState';
import { noop } from "../util/const.js";
/**
 * Effect hook that ignores the first render (not invoked on mount).
 *
 * @param effect Effector to run on updates
 * @param deps Dependencies list, as for `useEffect` hook
 */
export function useUpdateEffect(effect, deps) {
    const isFirstMount = useFirstMountState();
    useEffect(isFirstMount ? noop : effect, deps);
}
