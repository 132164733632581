export var isURLSearchParams = function (value) {
    return (typeof URLSearchParams !== 'undefined' && value instanceof URLSearchParams);
};
export var isFormData = function (value) {
    return typeof FormData !== 'undefined' && value instanceof FormData;
};
export var isPlainObject = function (value) {
    if (value == null) {
        return false;
    }
    var proto = Object.getPrototypeOf(value);
    return proto === null || proto === Object.prototype;
};
export var isTransformable = function (value) {
    return (Array.isArray(value) ||
        isPlainObject(value) ||
        isFormData(value) ||
        isURLSearchParams(value));
};
// Dirty hack for unexported AxiosHeaders.
// Don't handle it as Transformable to reduce the scope of the impact.
export var isAxiosHeaders = function (value) {
    var _a, _b;
    if (value == null) {
        return false;
    }
    return ((_b = (_a = Object.getPrototypeOf(value)) === null || _a === void 0 ? void 0 : _a.constructor) === null || _b === void 0 ? void 0 : _b.name) === 'AxiosHeaders';
};
