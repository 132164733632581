import React, { ReactNode } from "react";
import { Trans } from "react-i18next";

import { classNames } from "../../utils";
import { Code } from "../ui/Code";

type Props = {
  label: string;
  error?: string;
  explanation?: string;
  children: ReactNode;
  className?: string;
  hiddenLabel?: boolean;
};

export default function FormInput({ label, error, explanation, children, className, hiddenLabel }: Props) {
  hiddenLabel ??= false;

  return (
    <label className={classNames("flex flex-col w-full mb-6 space-y-1.5", error && "group has-error", className)}>
      <div
        className={classNames(
          "text-sm font-semibold text-gray-600 dark:text-gray-300",
          hiddenLabel && "hidden sm:block invisible",
        )}
      >
        {label}
      </div>
      {children}
      {explanation && !error && (
        <p className="text-sm text-gray-400 dark:text-gray-500">
          <Trans components={{ code: <Code /> }}>{explanation}</Trans>
        </p>
      )}
      {error && (
        <p className="text-sm text-red-500" data-role="error_message">
          {error}
        </p>
      )}
    </label>
  );
}

export function NoLabelFormInput({ error, explanation, children, className }: Omit<Props, "hiddenLabel" | "label">) {
  return (
    <div className={classNames("flex flex-col w-full space-y-1.5", error && "group has-error", className)}>
      {children}
      {explanation && !error && (
        <p className="text-sm text-gray-400 dark:text-gray-500">
          <Trans components={{ code: <Code /> }}>{explanation}</Trans>
        </p>
      )}
      {error && (
        <p className="text-sm text-red-500" data-role="error_message">
          {error}
        </p>
      )}
    </div>
  );
}

export function scrollErrorIntoView(scope: HTMLElement | Document = document) {
  const error = scope.querySelector("[data-role=error_message]");
  if (error) {
    error.scrollIntoView({ behavior: "smooth", block: "center" });
  }
}
