import { __assign, __read, __spreadArray, __values } from "tslib";
import { camelCase as camelCaseString } from 'camel-case';
import { snakeCase as snakeCaseString } from 'snake-case';
import { headerCase as headerCaseString } from 'header-case';
import { applyCaseOptions, preserveSpecificKeys } from './decorators';
import { isFormData, isTransformable, isURLSearchParams } from './util';
var caseFunctions = {
    snake: snakeCaseString,
    camel: camelCaseString,
    header: headerCaseString,
};
var transformObjectUsingCallbackRecursive = function (data, fn, overwrite) {
    var e_1, _a, e_2, _b, e_3, _c;
    if (!isTransformable(data)) {
        return data;
    }
    /* eslint-disable no-console */
    // Check FormData/URLSearchParams compatibility
    if ((isFormData(data) || isURLSearchParams(data)) &&
        (!data.entries || (overwrite && !data.delete))) {
        var type = isFormData(data) ? 'FormData' : 'URLSearchParams';
        var polyfill = isFormData(data)
            ? 'https://github.com/jimmywarting/FormData'
            : 'https://github.com/jerrybendy/url-search-params-polyfill';
        if (typeof navigator !== 'undefined' &&
            navigator.product === 'ReactNative') {
            // You cannot transform FormData/URLSearchParams on React Native
            console.warn("Be careful that ".concat(type, " cannot be transformed on React Native. If you intentionally implemented, ignore this kind of warning: https://facebook.github.io/react-native/docs/debugging.html"));
        }
        else {
            if (!data.entries) {
                // You need to polyfill `entries` method
                console.warn("You must use polyfill of ".concat(type, ".prototype.entries() on Internet Explorer or Safari: ").concat(polyfill));
            }
            if (overwrite && !data.delete) {
                // You need to polyfill `delete` method for overwriting
                console.warn("You must use polyfill of ".concat(type, ".prototype.delete() on Internet Explorer or Safari: ").concat(polyfill));
            }
        }
        return data;
    }
    /* eslint-enable no-console */
    var prototype = Object.getPrototypeOf(data);
    // Storage of new values.
    // New instances are created when overwriting is disabled.
    var store = overwrite
        ? data
        : !prototype
            ? Object.create(null)
            : new prototype.constructor();
    // We need to clean up all entries before overwriting.
    var series;
    if (isFormData(data) || isURLSearchParams(data)) {
        // Create native iterator from FormData/URLSearchParams
        series = data.entries();
        if (overwrite) {
            // When overwriting, native iterator needs to be copied as array.
            series = __spreadArray([], __read(series), false);
            try {
                for (var series_1 = __values(series), series_1_1 = series_1.next(); !series_1_1.done; series_1_1 = series_1.next()) {
                    var _d = __read(series_1_1.value, 1), key = _d[0];
                    data.delete(key);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (series_1_1 && !series_1_1.done && (_a = series_1.return)) _a.call(series_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    }
    else {
        // Create array from objects
        series = Object.entries(data);
        // Array keys never change, so we don't need to clean up
        if (overwrite && !Array.isArray(data)) {
            try {
                for (var series_2 = __values(series), series_2_1 = series_2.next(); !series_2_1.done; series_2_1 = series_2.next()) {
                    var _e = __read(series_2_1.value, 1), key = _e[0];
                    delete data[key];
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (series_2_1 && !series_2_1.done && (_b = series_2.return)) _b.call(series_2);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    }
    try {
        for (var series_3 = __values(series), series_3_1 = series_3.next(); !series_3_1.done; series_3_1 = series_3.next()) {
            var _f = __read(series_3_1.value, 2), key = _f[0], value = _f[1];
            if (isFormData(store) || isURLSearchParams(store)) {
                store.append(fn(key), value);
            }
            else if (key !== '__proto__') {
                store[fn(typeof key === 'string' ? key : "".concat(key))] =
                    transformObjectUsingCallbackRecursive(value, fn, overwrite);
            }
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (series_3_1 && !series_3_1.done && (_c = series_3.return)) _c.call(series_3);
        }
        finally { if (e_3) throw e_3.error; }
    }
    return store;
};
var transformObjectUsingCallback = function (data, fn, options) {
    fn = applyCaseOptions(fn, __assign({ stripRegexp: /[^A-Z0-9[\]]+/gi }, options === null || options === void 0 ? void 0 : options.caseOptions));
    if (options === null || options === void 0 ? void 0 : options.preservedKeys) {
        fn = preserveSpecificKeys(fn, options.preservedKeys);
    }
    return transformObjectUsingCallbackRecursive(data, fn, (options === null || options === void 0 ? void 0 : options.overwrite) || false);
};
export var createObjectTransformer = function (fn) {
    return function (data, options) {
        return transformObjectUsingCallback(data, fn, options);
    };
};
export var createObjectTransformerOf = function (functionName, options) {
    return createObjectTransformer((options === null || options === void 0 ? void 0 : options[functionName]) || caseFunctions[functionName]);
};
export var createObjectTransformers = function (options) {
    var e_4, _a;
    var functionNames = Object.keys(caseFunctions);
    var objectTransformers = {};
    try {
        for (var functionNames_1 = __values(functionNames), functionNames_1_1 = functionNames_1.next(); !functionNames_1_1.done; functionNames_1_1 = functionNames_1.next()) {
            var functionName = functionNames_1_1.value;
            objectTransformers[functionName] = createObjectTransformerOf(functionName, options);
        }
    }
    catch (e_4_1) { e_4 = { error: e_4_1 }; }
    finally {
        try {
            if (functionNames_1_1 && !functionNames_1_1.done && (_a = functionNames_1.return)) _a.call(functionNames_1);
        }
        finally { if (e_4) throw e_4.error; }
    }
    return objectTransformers;
};
