import { z } from "zod";
import { Product } from "./partials/Product";
import { Feature } from "./partials/Feature";

export const PlanPeriod = z.enum(["month", "year", "quarter", "six_month", "two_month"]);
export type PlanPeriod = z.infer<typeof PlanPeriod>;

export const PlanLicenseType = z.enum(["agent", "seat", "concurrent"]);
export type PlanLicenseType = z.infer<typeof PlanLicenseType>;

const basePlanSchema = z.object({
  id: z.string(),
  period: PlanPeriod,
  name: z.string(),
  monthlyPricePerLicense: z.number(),
  price: z.number(),
  monthlyPrice: z.number(),
  features: z.array(Feature),
  products: z.array(Product),
  formattedMonthlyPrice: z.string(),
  yearlySaving: z.number().nullable(),
  formattedYearlySaving: z.string().nullable(),
  currency: z.enum(["gbp", "usd", "eur"]),
  current: z.boolean(),
  perLicense: z.boolean(),
  invoiced: z.boolean(),
  sessionTimeCreditHours: z.number().nullable(),
  sessionTimeCreditHoursMonthly: z.number().nullable(),
  topupAmount: z.number().nullable(),
  topupHours: z.number().nullable(),
  sessionTimeCredit: z.boolean(),
  minLicenses: z.number(),
  maxLicenses: z.number(),
  licenses: z.number(),
  licenseType: PlanLicenseType,
  formattedPrice: z.string(),
  formattedMinPrice: z.string(),
  formattedPricePerLicense: z.string(),
  formattedMonthlyPricePerLicense: z.string(),
  formattedBillingFrequency: z.string(),
  formattedPeriod: z.string(),
  formattedPriceDescription: z.string(),
  formattedTopupPrice: z.string().nullable(),
  type: z.literal("plan"),
});

export type Plan = z.infer<typeof basePlanSchema> & {
  yearly: Plan | null;
};

export const Plan: z.ZodType<Plan> = basePlanSchema.extend({
  yearly: z.lazy(() => Plan.nullable()),
});
