import { createContext, useState, useRef, useContext, useCallback } from "react";
import Modal from "../components/ui/Modal";

type ProviderProps = {
  children: JSX.Element;
};

type ModalDetails = Omit<React.ComponentProps<typeof Modal>, "disappearing" | "children" | "onClick" | "loading"> & {
  content: React.ReactNode;
  replace?: boolean;
  onClick?: (accept: boolean) => void | Promise<boolean>;
};

const ModalContext = createContext({
  addModal: (modal: ModalDetails) => false as boolean,
  removeModal: () => {},
});

export function useModal() {
  return useContext(ModalContext);
}

export function ModalProvider({ children }: ProviderProps): JSX.Element {
  const [modalDetails, setModalDetails] = useState<ModalDetails | undefined>(undefined);
  const [modalDisappearing, setModalDisappearing] = useState(false);
  const [loading, setLoading] = useState(false);
  const modalDetailsRef = useRef<ModalDetails>();
  modalDetailsRef.current = modalDetails;
  const currentModalId = useRef<number>(0);
  const addModalWithinClick = useRef(false);

  const removeTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  async function onModalClick(option: boolean) {
    setLoading(true);
    const modalId = currentModalId.current;
    addModalWithinClick.current = true;
    const clear = await modalDetailsRef.current?.onClick?.(option);
    addModalWithinClick.current = false;
    setLoading(false);
    if (clear === false) {
      return;
    }

    if (modalId === currentModalId.current) {
      removeModal();
    }
  }

  const removeModal = useCallback(() => {
    setModalDisappearing(true);

    removeTimeoutRef.current = setTimeout(() => {
      setModalDetails(undefined);
      setModalDisappearing(false);
    }, 300);
  }, []);

  const addModal = useCallback((modal: ModalDetails): boolean => {
    if (modalDetailsRef.current && !modal.replace) {
      return false;
    }
    currentModalId.current++;
    if (!addModalWithinClick.current && !removeTimeoutRef.current) {
      modalDetailsRef.current?.onClick?.(false);
    }

    setModalDisappearing(false);
    setModalDetails(modal);

    clearTimeout(removeTimeoutRef.current);

    return true;
  }, []);

  return (
    <ModalContext.Provider value={{ addModal, removeModal }}>
      {modalDetails && (
        <Modal {...modalDetails} disappearing={modalDisappearing} loading={loading} onClick={onModalClick}>
          {modalDetails.content}
        </Modal>
      )}
      {children}
    </ModalContext.Provider>
  );
}
